import { useMutation } from "@apollo/client"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { Button, Stack, TextField, Typography } from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"
import gql from "graphql-tag"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import StyledTable from "../components/StyledTable"
import { clearCart, removeItem, setAmount } from "../features/cart/cartSlice"
import Loading from "./Loading"

import { rowActionCell } from "../utils/utils"

const gqlAddInvoice = gql`
    mutation gqlAddInvoice($positions: [invoiceInputType!]!) {
        addInvoice(positions: $positions) {
            invoiceNum
        }
    }
`

const CurrentInvoice = () => {
    const dispatch = useDispatch()
    const { cartItems, total, amount } = useSelector(store => store.cart)

    const { keycloak, initialized } = useKeycloak()

    const [addInvoice, { data, loading, error }] = useMutation(gqlAddInvoice, {
        fetchPolicy: "no-cache", // Doesn't check cache before making a network request
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })

    const handlePurchase = () => {
        const positions = cartItems.map(item => {
            return { orderId: item.id, pos: item.pos }
        })

        addInvoice({ variables: { positions } })
            .then(() => {})
            .catch(err => {
                console.log(err)
            })

        dispatch(clearCart())
    }

    const cachedInvoiceItems = useMemo(() => {
        return cartItems
    }, [cartItems])

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography sx={{ width: "150px", minWidth: "150px" }}>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN
                    </Typography>
                ),
                accessor: "pn",
                Cell: ({ row: { original }, value }) => {
                    return <Typography sx={{ width: "150px", minWidth: "150px" }}>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value: initialValue }) => {
                    const [value, setValue] = useState(initialValue)

                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <TextField
                                id="amount-req"
                                variant="outlined"
                                size="small"
                                type="number"
                                value={value}
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    },
                                }}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}
                                onBlur={() => {
                                    dispatch(setAmount({ id: original.id, pos: original.pos, amount: value }))
                                }}
                            />
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Дата отгрузки
                    </Typography>
                ),
                accessor: "delivery_date", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
        ],
        []
    )
    const removeItemFromInvoice = original => {
        dispatch(removeItem({ id: original.id, pos: original.pos }))
    }
    return (
        <>
            {/* <Typography variant="h4">Текущий счет</Typography> */}
            <StyledTable
                columns={columns}
                data={cachedInvoiceItems}
                onRowClick={(event, rowData) => {}}
                hooks={hooks => {
                    hooks.visibleColumns.push(columns => [
                        ...columns,
                        rowActionCell({
                            IconComponent: DeleteOutlineOutlinedIcon,
                            title: "Удаление позиции из счета",
                            question: "Вы подтверждаете удаление позиции из формируемого счета?",
                            dispatchAction: removeItemFromInvoice,
                        }),
                    ])
                }}
                actions={[]}
                globalSearch={false}
            />
            <Stack direction="row">
                <Button onClick={() => handlePurchase()}>Отправить на отгрузку</Button>{" "}
                <Button
                    onClick={() => {
                        dispatch(clearCart())
                    }}
                >
                    Очистить корзину
                </Button>
            </Stack>
            {loading && <Loading />}
            {error && <p>Error: ${error.message}</p>}
            {data && <h3>{`Создан новый счет: ${data.addInvoice.invoiceNum}`}</h3>}
        </>
    )
}

export default CurrentInvoice
