import React from "react"
import CurrentInvoice from "../components/CurrentInvoice"
import InvoiceHistory from "../components/InvoiceHistory"
import { Box, Tabs, Tab } from "@mui/material"
import TabPanel from "../components/TabPanel"
import UnderConstruction from "../components/UnderConstruction"

const Invoices = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const a11yProps = index => {
        return {
            id: `invoices-tab-${index}`,
            "aria-controls": `invoices-tabpanel-${index}`,
        }
    }

    return (
        <>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Новый счет" {...a11yProps(0)} />
                        <Tab label="Счета к отгрузке" {...a11yProps(1)} />
                        <Tab label="Архив счетов" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} aria="invoices">
                    <CurrentInvoice />
                </TabPanel>
                <TabPanel value={value} index={1} aria="invoices">
                    <InvoiceHistory />
                </TabPanel>
                <TabPanel value={value} index={2} aria="invoices">
                    <InvoiceHistory status={"В архиве"} />
                </TabPanel>
            </Box>
        </>
    )
}
export default Invoices
